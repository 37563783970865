<template>
	<span class="time">{{ formatedTimer }}</span>
</template>


<script>
import {GAME_EVENT} from '@/core/gameConfig'
import { EventBus } from '@/core/event-bus.js';

export default {
	name: 'Chrono',
	data() {
		return {
			timer: 45*60,
			timerInterval: null,
			timerDirection: 'desc'
		};
	},
	computed: {
		isTimerStart () {
			return this.$store.state.isTimerStart
		},
		formatedTimer(){
			let minutes = Math.floor(this.timer / 60);
			let seconds = this.timer - minutes * 60;

			return minutes + ':' + seconds.toLocaleString('fr-FR', {
				minimumIntegerDigits: 2,
				useGrouping: false
			});
		}
	},
	created() {
		EventBus.$on(GAME_EVENT.PAUSECHRONO, () => {
			this.stopTimer();
		});
		EventBus.$on(GAME_EVENT.REPRENDCHRONO, () => {
			this.startTimer();
		});
	},
	watch: {
		isTimerStart (newVal) {
			if (newVal) {
				this.startTimer()
			} else {
				this.stopTimer()
			}
		}
	},
	methods: {
		startTimer(){
			this.timerInterval = setInterval(() => {

				if (this.timerDirection === 'desc') {
					this.timer--;
				} else {
					this.timer++;
				}

				if (this.timer === 0 && this.timerDirection === 'desc') {
					this.stopTimer()

					// on passe le chrono en noir
					document.getElementsByClassName('time')[0].classList.add('noir')

					// on repart dans le sens inverse
					this.timerDirection = 'asc'
					this.startTimer()
				}

			}, 1000)
		},
		stopTimer(){
			clearInterval(this.timerInterval)
		}
	}
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.noir {
	color:black;
}
</style>
