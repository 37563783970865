
<template>
    <div class="wrapper connexion">
        <template v-if="step == 1">
            <!-- Logo Route du RPSO -->
            <section class="logo-top">
                <img class="mb-2"
                    src="img/common/logo-route-rpso.png" alt="La Route du RPSO"
                    srcset="img/common/logo-route-rpso-x2.png 2x">
            </section>

            <section class="header">
                <p>Entrez le code <br>
                pour déverrouiller le cadenas</p>

                <form class="code">
                    <input ref="chiffre1" v-model="form.chiffre1" v-on:focus="goToEndOfText($event)" v-on:keyup="nextInput($event)" type="text" maxlength="1">
                    <input ref="chiffre2" v-model="form.chiffre2" v-on:focus="goToEndOfText($event)" v-on:keydown="testValue($event)" v-on:keyup="nextInput($event)" type="text" maxlength="1">
                    <input ref="chiffre3" v-model="form.chiffre3" v-on:focus="goToEndOfText($event)" v-on:keydown="testValue($event)" v-on:keyup="nextInput($event)" type="text" maxlength="1">
                    <input ref="chiffre4" v-model="form.chiffre4" v-on:focus="goToEndOfText($event)" v-on:keydown="testValue($event)" v-on:keyup="nextInput($event)" type="text" maxlength="1">
                </form>

                <img class="mb-2"
                    src="img/common/icon-connexion.png" alt="Code"
                    srcset="img/common/icon-connexion-x2.png 2x">

                <p v-if="erreurCode" class="error">Code non valable. Merci de réessayer.</p>

                <button type="button" class="btn btn-blue" @click="unlock()"><span>Déverrouiller le cadenas</span></button>
            </section>

            <!-- Logo Janssen -->
            <section class="logo-bottom">
                <img class="mb-2"
                    src="img/common/logo-janssen-immunology.png" alt="Janssen Immunology"
                    srcset="img/common/logo-janssen-immunology-x2.png 2x">
            </section>
        </template>

        <template v-else-if="step == 2">
            <!-- Logo Route du RPSO -->
            <section class="logo-top">
                <img class="mb-2"
                    src="img/common/logo-route-rpso.png" alt="La Route du RPSO"
                    srcset="img/common/logo-route-rpso-x2.png 2x">
            </section>

            <section class="header">
                <p>Le cadenas est déverrouillé</p>
                <br><br>
                <img
                    src="img/common/icon-connexion-ok.png" alt="Code ok"
                    srcset="img/common/icon-connexion-ok-x2.png 2x">

                <button type="button" class="btn btn-blue" @click="goInstructions()"><span>Suivant</span></button>
            </section>

            <!-- Logo Janssen -->
            <section class="logo-bottom">
                <img class="mb-2"
                    src="img/common/logo-janssen-immunology.png" alt="Janssen Immunology"
                    srcset="img/common/logo-janssen-immunology-x2.png 2x">
            </section>
        </template>
    </div>
</template>


<script>
import {GAME_STEP} from '@/core/gameConfig'
export default {
  name: 'Connexion',
  data() {
    return {
      step : 1,
      form : {
        chiffre1: null,
        chiffre2: null,
        chiffre3: null,
        chiffre4: null,
      },
      erreurCode: false
    }
  },
  methods: {
    testValue(e) { // pour revenir à l'input précédent si la valeur était vide avant d'appuyer sur backspace
      if (e.target.value.length === 0 && e.keyCode === 8) { // backspace
        if (e.target.previousElementSibling !== null) {
          e.target.previousElementSibling.focus()
        }
      }
    },
    goToEndOfText(e) {
      let val = e.target.value
      e.target.value = val
    },
    nextInput(e) {
      if (e.keyCode === 8) { // backspace
        // on ne fait rien de plus, on a effacé le chiffre
      } else if (e.keyCode === 37) { // fleche gauche
        if (e.target.previousElementSibling !== null) {
          e.target.previousElementSibling.focus()
        }
      } else {
        if (e.target.nextElementSibling !== null) {
          e.target.nextElementSibling.focus()
        }
      }
    },
    unlock(){
      if (this.form.chiffre1 == 1 &&
          this.form.chiffre2 == 2 &&
          this.form.chiffre3 == 0 &&
          this.form.chiffre4 == 3
      ) {
        this.step = 2;
        this.erreurCode = false
      } else {
        this.erreurCode = true
      }
    },
    goNext(){
        this.step++;
    },

    // https://www.w3schools.com/howto/howto_js_fullscreen.asp
    openFullscreen() {
      let element = document.documentElement;

      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) { /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE11 */
        element.msRequestFullscreen();
      }

      this.goNext();
    },
    goInstructions(){
      this.$store.commit("setLoggedIn")
      this.$store.commit("setGameStep", GAME_STEP.INSTRUCTIONS)
    }
  }
}
</script>
