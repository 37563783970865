<template>
	<!-- div container flex qui centre le contenu -->
	<div class="global-container" id="globalContainer">

		<!-- div container qui fait de base la taille du jeu (1024*768) mais qui rétrécit sans scale (width/height calculé via js) -->
		<div class="game-container" v-bind:style="{width: styleObject.width+'px', height: styleObject.height+'px'}">

			<!-- div container qui se scale avec transform, positionné en absolute -->
			<div class="scaleable-container" ref="scaleableContainer" v-bind:style="{transform: 'scale(' + styleObject.scale + ')'}">
				<!-- contenu de taille 1024*768, c'est lui qui est scale, on met sa width/height pour conserver ses proportions -->
				<div class="fixed-container"  ref="fixedContainer">
					<div v-if="isLoggedIn === false" class="wrapper connexion">
						<Connexion />
					</div>

					<div v-else class="wrapper presentation">
						<Header @open-dossier="openDossier" @open-indices="openIndices" @open-ressources="openRessources" :dossierOpen="showDossier" :indicesOpen="showIndices" :ressourcesOpen="showRessources"/>
						<section class="content">
							<!-- DOSSIERS -->
							<DossierMedical @close-dossier="closeDossier" v-if="showDossier"/>
							<DossierRessources @close-dossier="closeRessources" v-if="showRessources"/>
							<DossierIndices  @close-dossier="closeIndices" v-if="showIndices"/>

							<!-- STEP -->
							<Intro v-if="getGameStep === GAME_STEP.INTRO" />
							<Instructions v-if="getGameStep === GAME_STEP.INSTRUCTIONS" />
							<PopinDirectives v-show="getGameStep === GAME_STEP.BUREAU || getGameStep === GAME_STEP.BUREAU2 || getGameStep === GAME_STEP.MAISON || getGameStep === GAME_STEP.OUTRO" />
							<!-- <Transition v-show="getGameStep === GAME_STEP.INIT || getGameStep === GAME_STEP.TRANSITION" /> -->
							<!-- <TransitionBack v-show="getGameStep === GAME_STEP.INIT || getGameStep === GAME_STEP.TRANSITIONBACK || getGameStep === GAME_STEP.OUTRO" /> -->

							<!-- COMPONENT POPIN DYNAMIQUE PRINCIPAL  -->
							<component v-bind:is="popinComponent" v-if="showPopin" @close="closePopin" :options="currentPopinParam"></component>

						</section>
					</div>
				</div>
			</div>

			<!-- div container du pano2vr, on lui donne une marge et une height dans le component pck il n'est pas full height -->
			<div class="pano-container" v-show="getGameStep === GAME_STEP.INIT || getGameStep === GAME_STEP.BUREAU || getGameStep === GAME_STEP.MAISON || getGameStep === GAME_STEP.BUREAU2 || getGameStep === GAME_STEP.OUTRO" v-bind:style="{marginTop: styleObject.marginTop+'px'}">
				<Pano2vr />
			</div>

		</div>
		<div style="display:none; position:absolute; z-index:5000; bottom: 0;">
		<!-- <div style="position:absolute; z-index:5000; bottom: 5px; border: 2px solid red; font-size:2rem; padding:5px;"> -->
			<button @click="openBureau1()">GO TO BUREAU 1 |</button>
			<button @click="openMaison()">GO TO MAISON |</button>
			<button @click="openBureau2()">GO TO BUREAU 2 |</button>
			<button @click="openOutro()">GO TO OUTRO |</button>
			<button @click="openEcranFin()">GO TO ECRAN FIN |</button>
			<button @click="recomputePano()">RECOMPUTE PANO2VR</button>
		</div>
	</div>

</template>


<script>
import Connexion from '@/components/Connexion'
import Header from '@/components/Header'
import Instructions from '@/components/Instructions'
import Intro from '@/components/Intro'
import Pano2vr from '@/components/Pano2vr.vue'
import PopinDirectives from '@/components/PopinDirectives.vue'
import DossierMedical from '@/components/Dossiers/DossierMedical.vue'
import DossierRessources from '@/components/Dossiers/DossierRessources.vue'
import DossierIndices from '@/components/Dossiers/DossierIndices.vue'
// import Transition from '@/components/Transition.vue'
// import TransitionBack from '@/components/TransitionBack.vue'
import {GAME_SIZE, GAME_STEP, GAME_EVENT} from '@/core/gameConfig'
import { EventBus } from '@/core/event-bus.js';


export default {
  name: 'App',
	data() {
		return {
			styleObject: {
				scale: 1,
				height: GAME_SIZE.height,
				width: GAME_SIZE.width,
				marginTop: 130
			},
			GAME_STEP: GAME_STEP,
			currentPopin: '',
			currentPopinParam: null,
			showPopin : false,
			showDossier : false,
			showRessources: false,
			showIndices: false,
		}
	},
	components : {
		Connexion,
		Header,
		Instructions,
		Intro,
		Pano2vr,
		PopinDirectives,
		DossierMedical,
		DossierRessources,
		DossierIndices,
		// Transition,
		// TransitionBack
	},
	computed: {
		isLoggedIn : function () {
			return this.$store.state.isLoggedIn
		},
		getGameStep: function () {
			return this.$store.state.gameStep
		},
		popinComponent() {
			console.log("currentPopin", this.currentPopin) // attention si on enleve ce console log, y'a tout qui plante
			return () => import(`@/components/` + this.currentPopin);
		}
	},
	watch: {
		showPopin() {

            if(this.showPopin){
                EventBus.$emit(GAME_EVENT.DISABLEPANO)
            }else{
                EventBus.$emit(GAME_EVENT.ENABLEPANO)
            }
        }
	},
	mounted() {
		this.resizeWindow()
	},
	created() {
		window.addEventListener("resize", this.resizeWindow);
		window.addEventListener('quiz', this.startQuiz);
		window.addEventListener('dossier', this.startDossier);
		window.addEventListener('ressources', this.startRessource);
		window.addEventListener('indices', this.startIndice);

		EventBus.$on(GAME_EVENT.LOADINDICE, (idx) => {
			console.log("LOADINDICE")
			this.startIndice({detail: idx})
		});
		EventBus.$on(GAME_EVENT.ADDRESSOURCE, () => {

			if(this.$store.state.dossiers.ressources.length == 11){ // DEFAULT : 11
				EventBus.$emit(GAME_EVENT.SHOWDIRECTIVE, 2);

			}
		});
		EventBus.$on('changeQuiz', (quiz, index_question) => {
			// HACK FOR
			setTimeout( () => {
				this.showPopin = false
				if (quiz == "vrai-faux"){
					this.currentPopin = "Quiz/QuizVraiFaux"
				} else if (quiz == "radio-button"){
					this.currentPopin = "Quiz/QuizRadioButton"
				} else if (quiz == "radio-button-chat"){
					this.currentPopin = "Quiz/QuizChat"
				} else if (quiz == "qcm-bg-messagerie"){
					this.currentPopin = "Quiz/QuizQCMBackgroundMessagerie"
				} else if (quiz == "qcm-bg-ordonnancier"){
					this.currentPopin = "Quiz/QuizQCMBackgroundOrdonnancier"
				} else if (quiz == "quiz-fin"){
					this.currentPopin = "Quiz/OrdiMedecinFin"
				} else {
					this.currentPopin = "Quiz/QuizQCM"
				}

				this.currentPopinParam = { "currentQuestion": index_question }
				this.showPopin = true
			}, 100)

		});
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeWindow);
		window.removeEventListener("quiz", this.startQuiz);
		window.removeEventListener("dossier", this.startDossier);
		window.removeEventListener("ressources", this.startRessource);
		window.removeEventListener("indices", this.startRessource);
	},
	methods: {
		// openArmoire() {
		// 	EventBus.$emit(GAME_EVENT.CHANGEPANO, GAME_STEP.BUREAU2);
		// },
		openBureau1() {
			this.$store.commit("setLoggedIn")
			this.$store.commit("setGameStep", GAME_STEP.BUREAU)
			EventBus.$emit('SHOWDIRECTIVE', 1);
			EventBus.$emit(GAME_EVENT.CHANGEPANO, GAME_STEP.BUREAU);
		},
		openMaison() {
			this.$store.commit("setLoggedIn")
			this.$store.commit("setGameStep", GAME_STEP.MAISON)
			EventBus.$emit('SHOWDIRECTIVE', 4);
			EventBus.$emit(GAME_EVENT.CHANGEPANO, GAME_STEP.MAISON);
		},
		openBureau2() {
			this.$store.commit("setLoggedIn")
			this.$store.commit("setGameStep", GAME_STEP.BUREAU2)
			EventBus.$emit('SHOWDIRECTIVE', 6);
			EventBus.$emit(GAME_EVENT.CHANGEPANO, GAME_STEP.BUREAU2);
		},
		openOutro() {
			this.$store.commit("setLoggedIn")
			this.$store.commit("setGameStep", GAME_STEP.OUTRO)
			EventBus.$emit('SHOWDIRECTIVE', 8);
		},
		openEcranFin() {
			this.$store.commit("setLoggedIn")
			this.$store.commit("setGameStep", GAME_STEP.OUTRO)
			EventBus.$emit('SHOWDIRECTIVE', 9);
		},
		// startTransition() {
		// 	this.$store.commit("setGameStep", GAME_STEP.TRANSITION)
		// 	EventBus.$emit('startTransition');
		// },
		recomputePano() {
			EventBus.$emit('recomputePano');
		},

		startQuiz(e) {
			console.log("START QUIZ", e.detail)
			if (e.detail == "postergenou") {
				if (this.$store.state.dossiers.ressources.indexOf('anatomie-genou') === -1) {
					this.currentPopin = "Quiz/QuizDragDropGenou"
					this.showPopin = true
				} else {
					console.log("dejà fait anatomie du genou")
				}
			} else if (e.detail == "postermain") {
				if (this.$store.state.dossiers.ressources.indexOf('anatomie-main') === -1) {
					this.currentPopin = "Quiz/QuizDragDropMain"
					this.showPopin = true
				} else {
					console.log("dejà fait anatomie de la main")
				}

			} else if (e.detail == "toise") {
				if (this.$store.state.dossiers.medical.indexOf('imc') === -1) {
					this.currentPopin = "Quiz/QuizIMC"
					this.showPopin = true
				} else {
					console.log("dejà fait IMC")
				}

			} else if (e.detail == "ordi") {
				if (this.$store.state.dossiers.medical.indexOf('mail-question-dermato') === -1) {
					this.currentPopin = "Quiz/OrdiMedecin"
					this.showPopin = true
				} else {
					console.log("dejà fait ordinateur")
				}

			} else if (e.detail == "ordipatient") {
				if (this.$store.state.dossiers.indices.indexOf('fake-news') === -1) {
					this.currentPopin = "Quiz/QuizVraiFaux"
					this.showPopin = true
				} else {
					console.log("dejà fait ordipatient")
				}

			} else if (e.detail == "ipad") {
				if (this.$store.state.dossiers.medical.indexOf('rdv-rhumato-medilib') === -1) {
					this.currentPopin = "Quiz/Tablette"
					this.showPopin = true
				} else {
					console.log("dejà fait tablette")
				}

			} else if (e.detail == "poubelle") {
				if (this.$store.state.dossiers.indices.indexOf('poubelle') === -1) {
					this.currentPopin = "Quiz/QuizRadioButton"
					this.showPopin = true
				} else {
					console.log("dejà fait poubelle")
				}

			} else if (e.detail == "ordimedecin") {
				// if (this.$store.state.dossiers.indices.indexOf('poubelle') === -1) {
					this.currentPopin = "Quiz/OrdiMedecin2"
					this.showPopin = true
				// } else {
				// 	console.log("dejà fait poubelle")
				// }

			} else if (e.detail == "trypanophobie") {
				if (this.$store.state.dossiers.medical.indexOf('trypanophobie') === -1) {
					EventBus.$emit('changeQuiz', 'qcm', 7);
				} else {
					console.log("dejà fait trypanophobie")
				}

			} else{
				this.currentPopin = "Quiz/QuizQCM"
				this.showPopin = true
			}
		},
		startDossier(e) {
			console.log("START DOSS", e.detail)
			this.showPopin = false
			this.currentPopin = "Dossiers/DossierMedicalViewer"
			this.currentPopinParam = e.detail
			this.showPopin = true
		},
		startRessource(e) {
			console.log("START RESSOURCES", e.detail)
			this.showPopin = false
			this.currentPopin = "Dossiers/RessourceViewer"
			this.currentPopinParam = e.detail
			this.showPopin = true
		},
		startIndice(e) {
			console.log("START INDICE")
			this.currentPopin = "Dossiers/IndiceViewer"
			this.currentPopinParam = e.detail
			this.showPopin = true
		},
		closePopin() {
			this.showPopin = false
		},
		openDossier() {
			this.showRessources = false
			this.showIndices = false
			this.showDossier = true
		},
		closeDossier() {
			this.showDossier = false
		},
		openRessources() {
			this.showDossier = false
			this.showIndices = false
			this.showRessources = true
		},
		closeRessources() {
			this.showRessources = false
		},
		openIndices() {
			this.showDossier = false
			this.showRessources = false
			this.showIndices = true
		},
		closeIndices() {
			this.showIndices = false
		},
		resizeWindow() {
			let scale = Math.min(
				window.innerWidth / this.$refs.fixedContainer.clientWidth,
				window.innerHeight / this.$refs.fixedContainer.clientHeight
			);
			if( scale <= 1) {
				this.styleObject.scale = scale
				this.$store.commit("setScale", scale)
				this.styleObject.width = 1024*scale
				this.styleObject.height = 768*scale
				this.styleObject.marginTop = 130*scale
			}else{
				this.styleObject.scale = 1
				this.$store.commit("setScale", 1)
				this.styleObject.width = 1024
				this.styleObject.height = 768
			}
		}
	}

}
</script>


<style lang="scss">
.global-container{
	display: flex;
	width: 100%;
	height: 100%;

	justify-content: center;
	align-items: center;
	//background: rgba(243, 187, 187, 0.933);
}
.game-container{
	position: relative;
	left: 0;
	top: 0;

	width: 100%;
	height: 100%;
	max-height: 768px;
	max-width: 1024px;
	//background: #eeee;
	//border: 1px solid orange;
}
.scaleable-container {
	resize: both;
	position: absolute;
	max-height: 768px;
	width: 1024px;
	max-width: 1024px;
	height: 768px;
	position: relative;

	z-index: 1;
	//left: 50%;
	//top: 50%;
	//transform: translate(-50%, -50%);
	transform-origin: left top;
	top: 0;
	left: 0;
	pointer-events: none;
	button, a, input{
		pointer-events: auto;
	}
	//border: 1px solid green;
}
.fixed-container{
	//border: 1px solid red;
	position: relative;
	width: 1024px;
	max-width: 1024px;
	height: 768px;
}

.pano-container{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 0;
	width: 100%;
	max-width: 1024px;
	//margin-top: 130px;

}

</style>
