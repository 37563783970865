<template>
  <div id="modalmenu" class="modal" style="display:block;">
    <!-- EXEMPLE SOUS MENU / INDICES -->
    <div class="modal-menu">
        <div class="carousel" v-if="fetchedItems.length">
          <div class="carousel-inner">
              <VueSlickCarousel ref="carousel" :arrows="false" :dots="false"  @init="onInitCarousel">
                  <template>
                          <div class="item" v-for="(item, y) in fetchedItems" v-bind:key="y">
                              <img
                                  :src="item.image"
                                  :alt="item.label">
                          </div>
                  </template>
              </VueSlickCarousel>
          </div>

          <ul class="carousel-controls" v-if="fetchedItems.length > 1">
              <li>
                  <button class="prev" @click="showPrev()">
                      <img
                          src="img/fleche-left.png" alt="Précédent"
                          srcset="img/fleche-left-x2.png 2x">
                  </button>
              </li>
              <li>
                  <button @click="showNext()" class="next">
                      <img
                          src="img/fleche-right.png" alt="Suivant"
                          srcset="img/fleche-right-x2.png 2x">
                  </button>
              </li>
          </ul>
      </div>
    </div>

    <!-- MODAL FOOTER -->
    <div class="modal-footer">

        <!-- BTN FERMER -->
        <button type="button" class="btn btn-blue align-center" @click="close()"><span>Fermer le dossier médical</span></button>

    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

import jsonDossierMedical from '@/data/dossiermedical_objects.json'

import {GAME_EVENT} from '@/core/gameConfig'
import { EventBus } from '@/core/event-bus.js';


export default {
  name: 'DossierMedical',
  components: {
    VueSlickCarousel
  },
  datas() {
    return {
      showControl : false
    }
  },
  created() {
      EventBus.$emit(GAME_EVENT.DISABLEPANO)
  },
  computed: {

    items() {
      return this.$store.state.dossiers.medical
    },
    showControl() {
      if(this.$refs.carousel){
        console.log("SHOWCONTROL")
        return this.$refs.carousel.$options._renderChildren.length > 1
      }
      return false

    },
    fetchedItems() {
        let fetchedItems = [];
        this.items.forEach(element => {
            let res = jsonDossierMedical.find( r => {
                return r.label == element
            })
            fetchedItems.push(res)
        });
        return fetchedItems
    }
  },
  methods : {
    close() {
      EventBus.$emit(GAME_EVENT.ENABLEPANO)
      this.$emit('close-dossier')
    },
    showNext() {
      this.$refs.carousel.next()
    },
    showPrev() {
      this.$refs.carousel.prev()
    },
    onInitCarousel() {
      console.log("SHOWCONTROL",  this.$refs.carousel)
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
