 let GAME_STEP = {
    INIT : 'INIT',
    INSTRUCTIONS : 'instructions',
    INTRO : 'intro',
    BUREAU : 'bureau',
    MAISON : 'maison',
    BUREAU2 : 'bureau2',
    OUTRO : 'outro',
    // TRANSITION : 'transition',
    // TRANSITIONBACK : 'transitionback',
}

let GAME_SIZE = {
	width: 1024,
	height: 768
}

let GAME_EVENT = {
	ADDINDICE: 'ADD-INDICE',
	ADDRESSOURCE: 'ADD-RESSOURCE',
    CHANGEPANO: 'CHANGE-PANO',
    LOADINDICE: 'LOADINDICE',
    SHOWDIRECTIVE: 'SHOWDIRECTIVE',
    DISABLEPANO: 'DISABLEPANO',
    ENABLEPANO: 'ENABLEPANO',
    PAUSECHRONO: 'PAUSECHRONO',
    REPRENDCHRONO: 'REPRENDCHRONO'
}

let GAME_REQUIREMENTS = {
    bureau: {
        requirements: {
            dossier_medical: 3, // 4 en vrai, le dernier indice médical est dans le tunnel ordi, débloqué par les requirements ci-contre
            dossier_ressources: 4,
            dossier_indices: 2,
            // ! pour tests plus rapides de déverrouillage de l'ordi :
            // dossier_medical: 0,
            // dossier_ressources: 0,
            // dossier_indices: 1
        },
        launch: {
            type: 'directive',
            index: 2
        }
    },
    maison: {
        requirements: {
            dossier_medical: 5,
            dossier_ressources: 4,
            dossier_indices: 8,
            // ! pour tester juste la maison :
            // dossier_medical: 1,
            // dossier_ressources: 0,
            // dossier_indices: 6
        },
        launch: {
            type: 'directive',
            index: 5
        }
    },
    bureau2: {
        requirements: {
            dossier_medical: 6,
            dossier_ressources: 6,
            dossier_indices: 9,
            // ! pour tests plus rapides de déverrouillage de l'ordi :
            // dossier_medical: 0,
            // dossier_ressources: 0,
            // dossier_indices: 1
        },
        launch: {
            type: 'directive',
            index: 7
        }
    }
}


export {GAME_SIZE, GAME_STEP, GAME_EVENT, GAME_REQUIREMENTS};