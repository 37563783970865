<template>
    <div class="h638">

        <template v-if="step == 1">
            <section class="content instructions">
                <ul>
                    <li class="l1">
                        <span>À certains moments, vous pourrez enrichir le dossier médical de votre patient et à tout moment y accéder.</span> 
                        <span class="titre">Dossier Médical</span>
                    </li>
                    <li class="l2">
                        <span>À certains moments, vous pourrez ajouter des éléments <br>de connaissance à votre dossier ressources <br>et à tout moment y accéder.</span> 
                        <span class="titre">Ressources</span>
                    </li>
                    <li class="l3">
                        <span>À certains moments, vous pourrez ajouter des éléments <br>à votre dossier indices et à tout moment y accéder <br>pour vous aider à résoudre ce cas clinique.</span> 
                        <span class="titre">Indices</span>
                    </li>
                    <li class="l4">
                        <span>Étapes du cas clinique.</span> 
                        <span class="titre">Calendrier</span>
                    </li>
                    <li class="l5">
                        <span><strong>Vous avez 45 min pour résoudre le jeu</strong>. <br>Pour vous aider, le chrono est visible tout au long de l’expérience.</span> 
                        <span class="titre">Chrono</span>
                    </li>
                </ul>
            </section>

            <section class="footer">
                <button type="button" class="btn btn-blue" @click="goNext()"><span>J'ai compris</span></button>
            </section>
        </template>

        <template v-else-if="step == 2">
            <section class="content intro">
                <p>Bienvenue, nous vous invitons à découvrir l’histoire de Nicolas.</p>
                <p>Nicolas partagera tout au long du jeu <br>des informations importantes sur sa vie… mais pas tout !</p>
                <p>A vous de déterminer quels indices seront nécessaires à garder à l’esprit pour résoudre les différentes énigmes et optimiser sa prise en charge pour des résultats visibles et une amélioration de sa qualité de vie.</p>
            </section>

            <section class="footer">
                <button type="button" class="btn btn-blue" @click="startGame()"><span>Commencer le jeu</span></button>
            </section>
        </template>

    </div>
</template>


<script>
export default {
  name: 'Instructions',
  data() {
    return {
      step : 1,
    }
  },
  methods: {
    goNext() {
      this.step++;
    },
    startGame() {
      this.$store.commit("startTimer")
      this.$store.commit("setGameStep", 'intro')
    },
  }
}
</script>

