<template>
<div>
	<div id="container" style="width:100%;height:638px;overflow:hidden;" :style="{height: resizedHeight + 'px'}">
		<br>Loading...<br><br>
	</div>

	</div>
</template>


<script>
import { EventBus } from '@/core/event-bus.js';
import { GAME_STEP, GAME_EVENT} from '@/core/gameConfig'

let pano2vrPlayer = pano2vrPlayer
let pano2vrSkin = pano2vrSkin
let pano = pano
let skin = skin
export default {
	name: 'Pano2vr',
	data(){
		return {
			panoScale : 1,
			resizedHeight : 638,
		}
	},
	computed: {
		scale () {
			return this.$store.state.scale
		},
		gameStep () {
			return this.$store.state.gameStep
		},
	},
	watch: {
		scale () {
			console.log("THIS SCALE")
			this.resizedHeight = 638*this.scale
		},
		gameStep () {
			console.log("UPDATE PANORAMA", this.$store.state.gameStep)
			// pano.hideOnePolyHotspot('Poly06')
			// pano.hideOnePolyHotspot('Poly05')
			// pano.hideOnePolyHotspot('Poly01')
			// pano.hideOnePolyHotspot('Poly02')
			// pano.toggleOnePolyHotspot('Poly06')
			pano.updatePanorama()

			//this.resizedHeight = 638*this.scale
		}
	},
	methods: {
		// openNext() {
		// 	pano.openNext('{node2}')
		// }
	},
	mounted() {
		console.log("MOUNTED PANORAM", this.$store.state.gameStep)
		// create the panorama player with the container
		pano=new window.pano2vrPlayer("container");
		// add the skin object
		skin=new window.pano2vrSkin(pano);

		pano.readConfigUrlAsync("pano2vr/pano-medecin/pano.xml");

		//pano.on('imagesready', () => {

			// setTimeout( () => {
			// 	console.log("images ready", pano.getPolygonMode())
			// 	//pano.setPolygonMode(2)
			// 	console.log("images ready", pano.getPolygonMode())

			// 	// pano.hideOnePolyHotspot('#Poly06')
			// 	// pano.hideOnePolyHotspot('#Poly05')
			// 	// pano.hideOnePolyHotspot('Poly01')
			// 	// pano.hideOnePolyHotspot('Poly02')
			// 	// pano.hideOnePolyHotspot('Poly06')
			// 	//pano.hideOnePolyHotspot('Poly06')
			// 	console.log("'Poly06", pano.getHotspot('Poly06'))
			// 	let poly6 = pano.getHotspot('Poly06')
			// 	pano.removeHotspot('Poly06')
			// 	pano.updatePanorama()
			// 	setTimeout( () => {
			// 		console.log("REPAOOZEF", poly6);
			// 		pano.addHotspot(poly6.id, poly6.pan, poly6.tilt)
			// 		pano.updatePanorama()

			// 		}, 3000)
			// }, 3000)
		// })

		// pano.showOnePolyHotspot(id:String)

		EventBus.$on(GAME_EVENT.CHANGEPANO, scene => {
			//this.openNext()
			if (scene == GAME_STEP.BUREAU) {
				// pano.openNext('{node2}')
				pano.openNext('pano2vr/pano-medecin/pano.xml')
			}
			if (scene == GAME_STEP.BUREAU2) {
				// pano.openNext('{node2}')
				pano.openNext('pano2vr/pano-medecin-2/pano.xml')
			}
			if (scene == GAME_STEP.MAISON) {
				pano.openNext('pano2vr/pano-maison/pano.xml')
			}

		});

		EventBus.$on(GAME_EVENT.DISABLEPANO, () => {
			console.log(GAME_EVENT.DISABLEPANO)
			pano.setLockedMouse(true)
		});
		EventBus.$on(GAME_EVENT.ENABLEPANO, () => {
			console.log(GAME_EVENT.ENABLEPANO)
			pano.setLockedMouse(false)
		});
		EventBus.$on('recomputePano', () => {
			console.log("RECOMPUTE PANO")
			//pano.update()
			// create the panorama player with the container
			pano=new window.pano2vrPlayer("container");
			// add the skin object
			skin=new window.pano2vrSkin(pano);
			pano.openNext('pano2vr/pano-medecin/pano.xml')
		});
	}
}
</script>
