import Vue from 'vue'
import Vuex from 'vuex'

import { GAME_STEP, GAME_REQUIREMENTS } from '@/core/gameConfig'
import { EventBus } from '@/core/event-bus.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoggedIn : false,
    // isLoggedIn : true, // INIT = false
    isTimerStart : false,
    gameStep : GAME_STEP.INIT,
    // gameStep : GAME_STEP.BUREAU, // INIT = null
    dateInGame: '29 janvier 2021',
    changementDateInGame: {
      'intro': '29 janvier 2021',
      'maison': '20 février 2021',
      'bureau2': '4 mars 2021',
      'outro': '8 avril 2021'
    },
    dossiers : {
      medical : [],
      ressources: [],
      indices: []
    },
    scale: 1
  },
  mutations: {
    setLoggedIn() {
      this.state.isLoggedIn = true
    },
    startTimer() {
      this.state.isTimerStart = true
    },
    endTimer() {
      this.state.isTimerStart = false
    },
    setGameStep(state, gameStep) {
      this.state.gameStep = gameStep

      if (gameStep in this.state.changementDateInGame) {
        this.state.dateInGame = this.state.changementDateInGame[gameStep]
      }
    },

    addToDossier(state, {dossier, item}) {
      if (this.state.dossiers[dossier].indexOf(item) === -1) {
        this.state.dossiers[dossier].push(item);
      } else {
        console.log(item + " - déjà dans le dossier " + dossier);
      }


      // CHECK REQUIREMENTS
      let gameStepRequirements = GAME_REQUIREMENTS[this.state.gameStep]
      if(gameStepRequirements){
        if (gameStepRequirements.requirements.dossier_medical === this.state.dossiers.medical.length  &&
            gameStepRequirements.requirements.dossier_ressources === this.state.dossiers.ressources.length &&
            gameStepRequirements.requirements.dossier_indices === this.state.dossiers.indices.length
        ) {
          if (gameStepRequirements.launch !== undefined) {
            if (gameStepRequirements.launch.type == 'qcm') {
              EventBus.$emit('changeQuiz', 'qcm', gameStepRequirements.launch.index);
            } else if (gameStepRequirements.launch.type == 'directive') {
              EventBus.$emit('SHOWDIRECTIVE', gameStepRequirements.launch.index);
            }
          }
        }
      }

    },


    setScale(state, scale){
      this.state.scale = scale
    }
  },
  actions: {
  },
  modules: {
  }
})
