<template>
  <section class="header">

        <!-- Logo ROUTE -->
        <div class="logo">
            <img
                src="img/common/logo-route-rpso-small.png" alt="La Route du RPSO"
                srcset="img/common/logo-route-rpso-small-x2.png 2x">
        </div>

        <!-- Logo Janssen -->
        <img class="logo-janssen"
            src="img/common/logo-janssen-white.png" alt="Janssen Immunology"
            srcset="img/common/logo-janssen-white-x2.png 2x">


        <!-- Nav -->
        <div class="nav">
            <div class="datetime">
                <span class="date">{{ dateInGame }}</span>
                <Chrono />
            </div>

            <ul>
                <li class="nav-dossier" :class="{'active': dossierOpen}"><a @click="afficheDossier()">Dossier médical</a> <span class="num">{{ nbDossierMedical }} <span>/12</span></span></li>
                <li class="nav-ressources" :class="{'active': ressourcesOpen}"><a @click="afficheRessources()">Ressources</a> <span class="num">{{ nbDossierRessource }} <span>/6</span></span></li>
                <li class="nav-indices" :class="{'active': indicesOpen}"><a @click="afficheIndices()">Indices</a> <span class="num">{{ nbDossierIndice }} <span>/9</span></span></li>
            </ul>
        </div>
    </section>
</template>

<script>
import Chrono from '@/components/Chrono'
import { GAME_STEP} from '@/core/gameConfig'
//import screenfull from 'screenfull'

export default {
  name: 'Header',
  props: ['dossierOpen', 'ressourcesOpen', 'indicesOpen'],
  components : {
    Chrono,
  },
  computed: {
    dateInGame() {
      return this.$store.state.dateInGame
    },
    nbDossierMedical(){
      return this.$store.state.dossiers.medical.length
    },
    nbDossierRessource(){
      return this.$store.state.dossiers.ressources.length
    },
    nbDossierIndice(){
      return this.$store.state.dossiers.indices.length
    },
    gameStep () {
			return this.$store.state.gameStep
		},
  },
  methods : {
    // https://www.w3schools.com/howto/howto_js_fullscreen.asp
    // https://developer.mozilla.org/fr/docs/Web/API/Document/exitFullscreen
    // toggleFullscreen() {
      // if (screenfull.isEnabled) {
      //   screenfull.request();
      // }
    //   let element = document.documentElement;

    //   if (document.fullscreenElement) {
    //     document.exitFullscreen()
    //   } else {
    //     if (element.requestFullscreen) {
    //       element.requestFullscreen();
    //     } else if (element.webkitRequestFullscreen) { /* Safari */
    //       element.webkitRequestFullscreen();
    //     } else if (element.msRequestFullscreen) { /* IE11 */
    //       element.msRequestFullscreen();
    //     }
    //   }
    // },
    afficheDossier() {
      if(this.gameStep != GAME_STEP.INSTRUCTIONS && this.gameStep != GAME_STEP.INTRO) {
        this.$emit('open-dossier')
      }

    },
    afficheIndices() {
      if(this.gameStep != GAME_STEP.INSTRUCTIONS && this.gameStep != GAME_STEP.INTRO) {
        this.$emit('open-indices')
      }
    },
    afficheRessources() {
      if(this.gameStep != GAME_STEP.INSTRUCTIONS && this.gameStep != GAME_STEP.INTRO) {
        this.$emit('open-ressources')
      }
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
