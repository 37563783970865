<template>
    <section class="modal-instructions" :class="styleClass">

        <template v-if="id == 1">
            <div class="inner">
                <p class="line"><img
                    src="img/common/icon-loupe.png" alt="Indices"
                    srcset="img/common/icon-loupe-x2.png 2x"></p>
                <p>Trouvez le courrier d'adressage et explorez le bureau à la recherche d'indices pour cette première consultation. </p>
                <p>Pensez ensuite à écrire à votre confrère dermatologue.</p>
            </div>
            <button class="btn btn-blue align-center" type="button" @click="toggle()"><span>Explorer maintenant</span></button>
        </template>

        <template v-if="id == 2">
            <div class="bravo">
                <h2>BRAVO !</h2>
                <p>Vous avez trouvé tous les indices !</p>
                <p>Vous pouvez à présent accéder à l'ordinateur <br>et adresser un mail à votre confrère dermatologue.</p>
                <button type="button" class="btn btn-blue align-right" @click="toggle()"><span>Suivant</span></button>
            </div>
        </template>

        <template v-if="id == 3">
            <div class="transition-immeuble">
                <div class="inner">
                    <p>Nicolas est à présent rentré chez lui, mais il a oublié de programmer son prochain rendez-vous.</p>
                    <p>Aidez le à prendre son rendez-vous sur « Médilib » et profitez-en pour collecter d’autres indices pouvant orienter votre prescription. </p>
                </div>
                <button type="button" class="btn btn-blue align-right" @click="goMaison()"><span>Suivant</span></button>
            </div>
        </template>

        <template v-if="id == 4">
            <div class="inner">
                <p class="line"><img
                    src="img/common/icon-loupe.png" alt="Indices"
                    srcset="img/common/icon-loupe-x2.png 2x"></p>
                <p>Explorez le domicile du patient à la recherche <br>d’indices complémentaires qui pourraient <br>vous aider dans la prise en charge de ce patient<br> et prenez rendez-vous sur Médilib.</p>
            </div>
            <button class="btn btn-blue align-center" type="button" @click="toggle()"><span>Explorer maintenant</span></button>
        </template>

        <template v-if="id == 5">
            <div class="bravo">
                <h2>BRAVO !</h2>
                <p>Vous avez trouvé tous les indices.</p>
                <button type="button" class="btn btn-blue align-right" @click="goBureau2()"><span>Suivant</span></button>
            </div>
        </template>

        <template v-if="id == 6">
            <div class="transition-bureau">
                <div class="inner">
                    <p>Prenez connaissance des nouvelles informations<br>et proposez un traitement adapté à Nicolas<br>avec l’ordonnancier de votre ordinateur.</p>
                </div>
                <button type="button" class="btn btn-blue align-right" @click="toggle()"><span>Suivant</span></button>
            </div>
        </template>

        <template v-if="id == 7">
            <div class="bravo">
                <h2>BRAVO !</h2>
                <p>Vous avez trouvé tous les indices !</p>
                <p>Vous pouvez à présent accéder à l'ordinateur <br>et faire votre ordonnance.</p>
                <button type="button" class="btn btn-blue align-right" @click="toggle()"><span>Suivant</span></button>
            </div>
        </template>

        <template v-if="id == 8">
            <div class="transition-bureau">
                <div class="inner">
                    <p>Nicolas vous recontacte un mois plus tard.</p>
                    <p>On lui a conseillé d’autres traitements et il souhaite savoir s’il s’agit de bons conseils.</p>
                    <p>Il est connecté sur l’application de discussion de votre ordinateur.</p>
                </div>
                <button type="button" class="btn btn-blue align-right" @click="goLastTunnel()"><span>Suivant</span></button>
            </div>
        </template>

        <template v-if="id == 9">
            <div class="ecran-fin">
                <h2>Bravo !</h2>
                <h3>Vous avez résolu le jeu.</h3>
                <p>Il est important de prendre en compte tout l'environnement du patient pour comprendre son état.</p>
                <p>Comme vous avez pu le constater à travers ce cas, l'accompagnement du patient va au-delà d'un traitement efficace.</p>
                <div class="btn-end">
                    <a href="/" class="btn btn-white"><span>Recommencer le jeu</span></a>
                </div>
            </div>
        </template>

    </section>
</template>

<script>
import {GAME_STEP, GAME_EVENT} from '@/core/gameConfig'
import { EventBus } from '@/core/event-bus.js';

export default {
    name: 'PopinDirectives',
    data() {
        return {
            styleClass : 'open',
            id: 1
        }
    },
    created() {
        EventBus.$on(GAME_EVENT.SHOWDIRECTIVE, (id) => {
			this.id = id
            this.open()
		});
    },
    computed: {
        gameStep () {
			return this.$store.state.gameStep
		},
    },
    watch: {
		gameStep (step) {
            console.log("GAME STEP", step)
            if(step == GAME_STEP.BUREAU) {
                this.open()
            }
		},
        styleClass() {
            console.log("wxath", this.styleClass)
            if(this.styleClass == 'open'){
                EventBus.$emit(GAME_EVENT.DISABLEPANO)
            }else{
                EventBus.$emit(GAME_EVENT.ENABLEPANO)
            }
        }
	},
    methods: {
        toggle() {
            if (this.styleClass == 'open'){
                this.styleClass = ''
            }else{
                this.styleClass = 'open'
            }
        },
        open() {
            this.styleClass = 'open'
        },
        goMaison() {
            this.styleClass = ''
			this.$store.commit("setGameStep", GAME_STEP.MAISON)
            EventBus.$emit('SHOWDIRECTIVE', 4);
            EventBus.$emit(GAME_EVENT.CHANGEPANO, GAME_STEP.MAISON);
        },
        goBureau2() {
            this.styleClass = ''
			this.$store.commit("setGameStep", GAME_STEP.BUREAU2)
            EventBus.$emit('SHOWDIRECTIVE', 6);
            EventBus.$emit(GAME_EVENT.CHANGEPANO, GAME_STEP.BUREAU2);
        },
        goLastTunnel(){
            this.styleClass = ''

            // on relance le chrono après la pause
            EventBus.$emit(GAME_EVENT.REPRENDCHRONO);

			this.$store.commit("setGameStep", GAME_STEP.OUTRO)
            EventBus.$emit('changeQuiz','quiz-fin')
        },
        // goTransition() {
        //     this.styleClass = ''
        //     EventBus.$emit('changeQuiz','quiz', 9)
        // },
        // goBureau(){
        //     this.styleClass = ''
        //     EventBus.$emit('changeQuiz','quiz', 12)
        // },
    }
}
</script>

<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    .modal-instructions {
        .btn.align-right {
            position: absolute;
            bottom: 4rem;
            right: 0;
        }

        .transition-immeuble {
            background: url("/img/bg-transition-immeuble.jpg") no-repeat 0 0;
            background-size: 100%;
            height: 63.6rem;
            padding-top: 15rem;

            .inner {
                color: black;
                margin-top: 0;
            }
        }

        .transition-bureau {
            background: url("/img/bg-transition-bureau.jpg") no-repeat 0 0;
            background-size: 100%;
            height: 63.6rem;
            padding-top: 15rem;

            .inner {
                color: black;
                margin-top: 0;
            }
        }

        .ecran-fin {
            background: url("/img/bg-fin.png") no-repeat 0 0;
            background-size: 100%;
            height: 63.6rem;
            padding: 5rem 15rem;
            text-align: center;

            h2 {
                color: $green;
                font-size: 12rem;
                font-weight: 300;
                line-height: 1;
                margin: 0;
            }

            h3 {
                color: $green;
                font-size: 4.5rem;
                font-weight: 400;
                margin: 0 0 3rem;
            }

            p {
                color: white;
                font-size: 2rem;
                font-weight: 500;
            }

            .btn-end {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .btn {
                    margin-top: 2rem;

                    &-white {
                        background: white;
                        font-weight: 500;
                        padding: 0 5rem;

                        span {
                            color: $blue;
                        }

                        &:hover {
                            span {
                                color: white;
                            }

                            &:before {
                                background: $blue;
                            }
                        }
                    }
                }
            }
        }
    }

</style>