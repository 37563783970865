<template>
    <div class="h638">

        <template v-if="step == 1">
            <section class="content intro">
                <p>Le patient s’appelle Nicolas,</p>
                <p>un homme de 41 ans.</p>
                <p>Célibataire.</p>
                <p>Travaillant comme consultant dans le secteur bancaire.</p>
                <p>Actuellement en télétravail au moins 3 jours par semaine.</p>
            </section>

            <!-- FOOTER -->
            <section class="footer">
                <button type="button" class="btn btn-blue" @click="goNext()"><span>Suivant</span></button>
            </section>
        </template>

        <template v-else-if="step == 2">
            <div class="bg-intro"></div>
            <section class="content intro delay">
                <p>Vous voilà à l’hôpital où vous recevez Nicolas. <br>
                  Il vous est adressé par un confrère dermatologue.</p>
            </section>

            <section class="footer">
                <span></span>
                <button type="button" class="btn btn-blue align-right" @click="goBureauMedecin()"><span>Suivant</span></button>
            </section>
        </template>

    </div>
</template>


<script>
import {GAME_STEP} from '@/core/gameConfig'

export default {
  name: 'Intro',
  data() {
    return {
      step : 1,
    }
  },
  methods: {
    goNext() {
      this.step++;
    },
    goBureauMedecin() {
      this.$store.commit("setGameStep", GAME_STEP.BUREAU)
    }
  }
}
</script>

